export const heavy = `Type\tFrame\tDuration\tBody\tHead\tBurst\tTotal
Exotic\tLeviathan's Breath\t22.1\t28524\t32463\t35880\t715810
Exotic:Ember of Ashes\tOne-Thousand Voices\t23.6\t30919\t30919\t35089\t728450
Exotic\tWinterbite\t12.0\t30283\t30283\t40512\t364605
Exotic:Melee Only\tWinterbite\tInfinite\t18659\t18659\t21235\tInfinite
Legendary\tAdaptive Grenade Launcher\t18.2\t27209\t27209\t51527\t494661
Legendary\tRegnant\t9.5\t54851\t54851\t76700\t521080
Legendary\tCompressed Wave Frame\t16.7\t25457\t25457\t50550\t424617
Legendary\tHullabaloo\t8.0\t58385\t58385\t55605\t467079
Legendary\tRapid-Fire Grenade Launcher\t16.1\t24733\t24733\t51320\t399062
Legendary\tMarsilion-C\t7.7\t57046\t57046\t56452\t438968
Exotic:2x Grenades\tAnarchy\t120.0\t11073\t11073\t11073\t1328733
Exotic\tParasite\t27.2\t25846\t25846\t140807\t704037
Exotic\tSalvation's Grip\t16.3\t27861\t27861\t39579\t453572
Exotic\tThe Colony\t13.8\t24370\t24370\t36542\t336548
Exotic\tThe Prospector\t17.2\t33878\t33878\t56528\t583372
Legendary\tAggressive Linear Fusion Rifle\t28.3\t6023\t20725\t22182\t586923
Legendary\tDoomed Petitioner\t26.5\t6446\t28392\t28392\t751261
Legendary\tPrecision Linear Fusion Rifle\t28.0\t5711\t19650\t21802\t549413
Legendary\tTaipan-4FR\t36.4\t5855\t24177\t26163\t879060
Exotic\tSleeper Simulant\t17.5\t22960\t31739\t35605\t555433
Exotic:Marksman Sights\tThe Queenbreaker\t33.0\t7239\t24908\t28616\t822717
Legendary\tAdaptive Machine Gun\t65.7\t9071\t12725\t17696\t836280
Legendary\tSong of Ir Yut\t51.0\t16115\t22605\t24375\t1151892
Legendary\tHigh-Impact Machine Gun\t68.8\t8506\t11932\t16432\t821247
Legendary\tTerminus Horizon\t93.3\t11867\t16645\t20858\t1553515
Legendary\tRapid-Fire Machine Gun\t47.0\t10770\t14034\t20291\t659864
Legendary\tRetrofit Escapade\t78.7\t17024\t22183\t27190\t1746870
Exotic\tDeterministic Chaos\t62.4\t8963\t12997\t17231\t811385
Exotic\tGrand Overture\t48.0\t27137\t27137\t167832\t1301776
Exotic\tHeir Apparent\t31.6\t17759\t21417\t25577\t676775
Exotic\tThunderlord\t25.7\t20945\t28609\t28609\t736101
Exotic\tXenophage\t15.7\t29386\t29386\t38446\t461357
Legendary\tAdaptive Rocket Launcher\t17.3\t20729\t20729\t44799\t358396
Legendary\tThe Hothead\t10.9\t41616\t41616\t97104\t454446
Legendary\tAggressive Rocket Launcher\t17.3\t20729\t20729\t44799\t358396
Legendary\tCold Comfort\t12.2\t43604\t43604\t116249\t532845
Legendary\tHigh-Impact Rocket Launcher\t22.5\t18101\t18101\t40727\t407271
Legendary\tBraytech Osprey\t22.5\t29419\t29419\t66193\t661925
Legendary\tPrecision Rocket Launcher\t21.9\t18622\t18622\t40727\t407271
Legendary\tRoyal Entry\t14.4\t35862\t35862\t88277\t516419
Exotic\tDeathbringer\t22.1\t40008\t40008\t88218\t882179
Exotic:Ember of Ashes\tDragon's Breath\t19.8\t22631\t22631\t67883\t678831
Exotic\tEyes of Tomorrow\t27.6\t21051\t21051\t91740\t581019
Exotic\tGjallarhorn\t15.7\t26992\t26992\t65278\t424307
Exotic\tThe Wardcliff Coil\t19.1\t20092\t20092\t54766\t383361
Exotic\tTruth\t12.7\t34811\t34811\t42975\t443498
Exotic\tTwo-Tailed Fox\t23.0\t26199\t26199\t67069\t603625
Exotic\tLegend of Acrius\t24.8\t41866\t41866\t78711\t1038283
Exotic\tTractor Cannon\t10.5\t16252\t16252\t18894\t171295
Exotic\tD.A.R.C.I.\t18.9\t8601\t31427\t56992\t592719
Exotic\tIzanagi Rocket\t13.0\t34129\t53981\t97912\t701755
Exotic\tWhisper of the Worm\t24.2\t6454\t39960\t57942\t967639
Legendary\tAdaptive Sword\t20.9\t25163\t25163\t34652\t525728
Legendary\tNasreddin\t28.0\t33031\t33031\t34652\t923300
Legendary\tAggressive Sword\t24.7\t25330\t25330\t44689\t625658
Legendary\tCrown-Splitter\t33.1\t34305\t34305\t44689\t1135497
Legendary\tCaster Sword\t18.2\t25197\t25197\t30901\t458327
Legendary\tTemptation's Hook\t24.4\t29297\t29297\t30901\t713375
Legendary\tLightweight Sword\t30.0\t23854\t23854\t29792\t715056
Legendary\tGoldtusk\t40.1\t31759\t31759\t29792\t1273101
Legendary\tVortex Sword\t19.9\t23195\t23195\t37247\t461843
Legendary\tFalling Guillotine\t19.9\t34429\t34429\t40972\t685513
Exotic\tBlack Talon\t17.2\t29816\t29816\t55835\t511836
Exotic\tHeartshadow\t26.2\t24112\t24112\t41443\t631195
Exotic\tThe Lament\t30.4\t30439\t30439\t64804\t925969
Exotic\tWorldline Zero\t23.4\t23274\t23274\t112919\t544436`;
