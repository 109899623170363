export const colorCells = (selector, headers, maxValues) => {
  const cells = document.querySelectorAll(`${selector} td`);
  cells.forEach(cell => {
    const numericValue = parseInt(cell.innerHTML.replace(/,/g, ''));
    if (!isNaN(numericValue)) {
      const columnIndex = cell.cellIndex;
      cell.style.backgroundColor = getGradientColor(numericValue, headers[columnIndex], maxValues);
    } else {
      const stringValue = cell.innerHTML.replace(/,/g, '');
      if (stringValue === 'Infinite') {
        cell.style.backgroundColor = `rgb(147, 197, 132)`;
      }
    }
  });
}

export const sortRows = (column, wordColumns, sortOrder, selector) => {
  let sortedRows;
  if (column < wordColumns) {
    sortedRows = [...document.querySelectorAll(`${selector} tr`)].sort((a, b) => {
      const aValue = a.querySelector(`td:nth-child(${column + 1})`).innerHTML;
      const bValue = b.querySelector(`td:nth-child(${column + 1})`).innerHTML;
      if (sortOrder === 'asc') {
        return aValue < bValue ? 1 : -1;
      } else {
        return aValue > bValue ? 1 : -1;
      }
    });
  } else {
    sortedRows = [...document.querySelectorAll(`${selector} tr`)].sort((a, b) => {
      const aValue = parseFloat(a.querySelector(`td:nth-child(${column + 1})`).innerHTML.replace(/,/g, ''));
      const bValue = parseFloat(b.querySelector(`td:nth-child(${column + 1})`).innerHTML.replace(/,/g, ''));
      if (sortOrder === 'asc') {
        if (isNaN(aValue)) {
          return 1;
        } else if (isNaN(bValue)) {
          return -1;
        }

        return aValue > bValue ? 1 : -1;
      } else {
        if (isNaN(aValue)) {
          return -1;
        } else if (isNaN(bValue)) {
          return 1;
        }

        return aValue < bValue ? 1 : -1;
      }
    });
  }

  document.querySelector(selector).innerHTML = '';
  for (const sortedRow of sortedRows) {
    document.querySelector(selector).appendChild(sortedRow);
  }
}

export const addCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const getGradientColor = (value, column, maxValues) => {
  const minValue = 0;
  const maxValue = maxValues[column];
  const percentage = (value - minValue) / (maxValue - minValue);

  if (percentage >= 0 && percentage < 0.2) {
    return `rgb(255, 255, 255)`;
  } else if (percentage >= 0.2 && percentage < 0.4) {
    return `rgb(230, 248, 224)`;
  } else if (percentage >= 0.4 && percentage < 0.6) {
    return `rgb(201, 226, 198)`;
  } else if (percentage >= 0.6 && percentage < 0.8) {
    return `rgb(174, 217, 160)`;
  } else {
    return `rgb(147, 197, 132)`;
  }
}

export const addInfo = (cell, info) => {
  const infoIcon = document.createElement('i');
  infoIcon.classList.add('fa-solid', 'fa-circle-info');
  infoIcon.style.cursor = 'pointer';
  infoIcon.style.float = 'right';
  infoIcon.style.marginTop = '4px';
  infoIcon.style.marginRight = '4px';
  infoIcon.addEventListener('mouseover', () => {
    infoIcon.style.color = "rgba(51, 51, 51, 0.8)";
    const messageBox = document.createElement('div');
    messageBox.style.position = 'absolute';
    messageBox.style.backgroundColor = 'rgba(0,0,0,0.8)';
    messageBox.style.color = 'white';
    messageBox.style.borderRadius = '10px';
    messageBox.style.padding = '10px';
    messageBox.style.marginTop = "6px";
    messageBox.style.marginLeft = infoIcon.offsetLeft - 20 + "px";
    messageBox.style.zIndex = '1';
    messageBox.innerHTML = info;
    cell.appendChild(messageBox);

    const tail = document.createElement('div');
    tail.style.width = '0';
    tail.style.height = '0';
    tail.style.borderLeft = '10px solid transparent';
    tail.style.borderRight = '10px solid transparent';
    tail.style.borderBottom = '10px solid rgba(0,0,0,0.8)';
    tail.style.position = 'absolute';
    tail.style.top = '-9.5px';
    messageBox.appendChild(tail);
  });
  infoIcon.addEventListener('mouseout', () => {
    infoIcon.style.color = "rgba(51, 51, 51, 1)";
    const messageBox = cell.querySelector('div');
    cell.removeChild(messageBox);
  });
  cell.appendChild(infoIcon);
}

export const addArrowUp = (headersArray, currentSortColumn) => {
  headersArray.forEach(header => {
    let existingIcon = header.querySelector('i');
    if (existingIcon) {
      header.removeChild(existingIcon);
    }
  });
  let icon = document.createElement('i');
  if (currentSortColumn <= 0) {
    icon.classList.add('fa-solid', 'fa-arrow-up-z-a');
  } else {
    icon.classList.add('fa-solid', 'fa-arrow-up-short-wide');
  }
  icon.style.float = 'right';
  icon.style.paddingTop = '3px';
  icon.style.paddingRight = '3px';
  return icon
}

export const addArrowDown = (headersArray, currentSortColumn) => {
  headersArray.forEach(header => {
    let existingIcon = header.querySelector('i');
    if (existingIcon) {
      header.removeChild(existingIcon);
    }
  });
  let icon = document.createElement('i');
  if (currentSortColumn <= 0) {
    icon.classList.add('fa-solid', 'fa-arrow-down-a-z');
  } else {
    icon.classList.add('fa-solid', 'fa-arrow-down-wide-short');
  }
  icon.style.float = 'right';
  icon.style.paddingTop = '3px';
  icon.style.paddingRight = '3px';
  return icon
}
